@import "bourbon/bourbon";

body {
	font-family: "Raleway", sans-serif;
	font-weight: normal;
	font-size: 14px;
	min-width: 320px;
	position: relative;
	line-height: 1.42857;
	-webkit-font-smoothing: antialised;
	overflow-x: hidden; }


body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: red; }
body input:required:valid,
body textarea:required:valid {
	color: green; }

.hidden {
	display: none; }


.main-head {
	min-height: 620px;
	padding-top: 20px; }

.loader {
	background: none repeat scroll 0 0 #ffffff;
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 9999; }

.loader-inner {
	background-image: url("../img/load.gif");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: #fff;
	height: 60px;
	width: 60px;
	margin-top: -30px;
	margin-left: -30px;
	left: 50%;
	top: 50%;
	position: absolute; }

.logo-container {
	float: left; }

svg {
	height: 60px;
	width: 60px;
	z-index: 30;
	position: relative; }



//  toggle-menu

.toggle-menu {
	width: 60px;
	height: 60px;
	float: right;
	position: relative;
	z-index: 100;
	border: 0;
	position: fixed;
	right: 20px;
	top: 20px;
	background-color: #000;
	span {
		display: block; } }


$w: 34px;
$h: 3px;

.sandwich {
	width: $w;
	height: $w;
	position: absolute;
	top: 12px;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 200; }


.sw-topper {
	position: relative;
	top: 0;
	width: $w;
	height: $h;
	background: rgb(255, 255, 255);
	border: none;
	border-radius: 4px 4px 4px 4px;
	transition: transform 0.5s, top 0.2s; }


// transition-delay: 0.2s, 0s;

.sw-bottom {
	position: relative;
	width: $w;
	height: $h;
	top: $h * 2.2;
	background: rgb(255, 255, 255);
	border: none;
	border-radius: 4px 4px 4px 4px;
	transition: transform 0.5s, top 0.2s;
	transition-delay: 0.2s, 0s; }

.sw-footer {
	position: relative;
	width: $w;
	height: $h;
	top: $h * 4.5;
	background: rgb(255, 255, 255);
	border: none;
	border-radius: 4px 4px 4px 4px;
	transition: all 0.5s;
	transition-delay: 0.1s; }

.sandwich.active {
	.sw-topper {
		top: 10px;
		transform: rotate(-45deg); }
	.sw-bottom {
		top: 6px;
		transform: rotate(45deg); }
	.sw-footer {
		opacity: 0;
		top: 0;
		transform: rotate(180deg); } }

//  top-menu
.top-menu {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .85);
	z-index: 50;
	right: 0;
	top: 0;
	display: none;
	ul,
	li {
		list-style: none;
		padding: 0;
		margin: 0; }
	ul {
		margin-top: 180px;
		text-align: center;

		li {
			&:nth-child(1) a {
				@include animation-duration(.5s); }
			&:nth-child(2) a {
				@include animation-duration(1s); }
			&:nth-child(3) a {
				@include animation-duration(1.5s); }
			&:nth-child(4) a {
				@include animation-duration(2s); } }
		a {
			color: #fff;
			display: block;
			text-transform: uppercase;
			font-size: 12px;
			position: relative;
			height: 60px;
			outline: none;


			span {
				display: block;
				position: relative;
				top: -40px;
				left: 0;
				height: 100%;
				@include transition(all 0.5s ease);
				&:hover {
					background: rgba(255, 255, 255, .08); } } } } }

.top-wrapper {
	display: table;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	.top-desc {
		display: table-row;
		.top-centred {
			display: table-cell;
			text-align: center;
			vertical-align: middle; } }
	.top-text {
		display: inline-block;
		@include transition(all, 1s ease);
		h1 {
			display: inline-block;
			padding: 10px 35px;
			color: #fff;
			border: 8px solid #fff;
			text-transform: uppercase;
			font-size: 2.8em;
			margin-left: 20px;
			margin-right: 20px;
			background: rgba(0, 0, 0, .1);
			@include animation-delay(.5s); }
		p {
			margin: 0;
			text-transform: uppercase;
			@include animation-delay(1s); } } }

.h-opacity {
	opacity: .1;
	@include transition(all, 1s ease); }

section,
.main-head {
	position: relative; }

section {
	padding: 70px 0 90px;

	h2 {
		text-align: center;
		font-size: 24px;
		text-transform: uppercase;
		margin: 0 0 15px 0; }
	.personal-header {
		font-size: 13px;
		text-align: left;
		margin-bottom: 30px;
		color: #555; }
	h3 {
		text-align: center;
		text-transform: uppercase;
		font-weight: 400;
		font-size: 12px;
		margin-top: 0;
		margin-bottom: 35px; } }

.s-desc-wrap {
	text-align: center;
	.s-desc {
		display: inline-block;
		text-transform: uppercase;
		font-size: 12px;
		margin-bottom: 130px;
		&::after {
			content: "";
			width: 150px;
			height: 1px;
			position: absolute;
			margin-left: -75px;
			left: 50%;
			margin-top: 60px; } } }

.main-footer {
	padding: 50px 0;
	font-size: 13px;
	font-weight: 300;
	.social-wrap {
		float: right;
		line-height: 0;
		ul {
			margin: 0; } } }

.person {
	text-align: center;
	margin-bottom: 35px;
	img {
		max-width: 220px;
		display: inline-block;
		border-radius: 50%; } }

.s-about {
	p {
		margin-bottom: 25px; }
	ul,
	li {
		margin: 0;
		padding: 0;
		list-style: none; }
	li {
		margin-bottom: 10px; }
	.social-wrap {
		margin-top: 25px; } }

.social-wrap {
	li {
		display: inline-block;
		font-size: 20px;
		padding: 0 3px; } }

.resume-container {
	.left {
		text-align: right;
		border-right: 1px solid #d2d2d2;
		h3 {
			text-align: right; } }
	.right {
		text-align: left;
		h3 {
			text-align: left; } } }


.resume-item {
	margin-bottom: 35px;

	strong {
		font-weight: 600;
		font-style: normal;
		text-transform: uppercase; }

	.year {
		display: inline-block;
		margin-bottom: 20px;
		padding: 0 15px;
		color: #fff;
		font-weight: 600; }


	.resume-desc {
		font-style: italic; }

	p {
		margin-top: 10px; }

	&:last-child {
		margin-bottom: 0;
		p {
			margin-bottom: 0; } } }

.s-portfolio {
	ul,
	li {
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: center; }
	li {
		display: inline-block;
		margin: 0 10px;
		text-transform: uppercase;
		font-size: 12px;
		cursor: pointer;
		line-height: 2; }
	ul {
		margin-bottom: 50px; } }

.portfolio-item {
	display: none;
	padding: 0;
	img {
		width: 100%; }
	.portfolio-item-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .8);
		text-align: center;
		padding-top: 15%;
		opacity: 0;
		transition: all .5s; }
	&:hover .portfolio-item-content {
		opacity: 1;
		padding-top: 20%; }
	h3 {
		color: #fff;
		margin-bottom: 0; }
	a {
		background: transparent;
		border: 1px solid #fff;
		padding: 2px 10px;
		display: inline-block; } }

.portfolio-desc {
	background: #fff;
	max-width: 500px;
	margin: auto;
	p,
	h3 {
		padding: 20px 20px 0 20px; }
	h3 {
		margin-bottom: 0;
		text-align: center;
		text-transform: uppercase;
		font-weight: 400;
		margin-top: 20px;
		padding-top: 25px; }
	p {
		margin-top: 0; }
	img {
		margin-top: 10px;
		width: 100%; } }
.modal-box-content {
	position: relative; }

.contact-box {
	margin-bottom: 30px;
	h3 {
		text-align: left;
		color: #000;
		margin-bottom: 0;
		font-size: 14px; }
	.contacts-icon {
		font-size: 30px; }
	p {
		margin-top: 5px; } }

.main-form {
	button {
		border: 0;
		color: #fff;
		line-height: 40px;
		padding: 0 25px;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 600;
		margin-top: 10px;
		float: right; } }

.form-group {
	display: block;
	margin-bottom: 20px;
	input,
	textarea {
		display: block;
		width: 100%;
		border: 1px solid #ccc;
		margin-top: 5px;
		line-height: 40px;
		text-indent: 12px;
		font-size: 12px;
		background: transparent; } }
